.App {
  text-align: center;
}

html {
  touch-action: none;
}

body {
  background-image: url(./img/background.png);
  background-size: cover;
  touch-action: none;

}

.soundmachine {
  width: 23vw;
  pointer-events: none;

  top:20%;
  position: absolute;
}

.soundmachine-element {
  position: absolute;
  width:100%;
  pointer-events: none;
}

.logo-center {
  transition: 0.6s;
  right: 40%;
  
}

.logo-left {
  transition: 0.6s;
  right: 74%;
  
}




.navbar-brand{
  height:100%;
  display: block;
  width:auto;
  padding:0;
  margin:0;
}

.navbar{
  padding-top:0;
  padding-bottom: 0;
  height: 50px;
}

h1 {
  font-weight: 700;
  font-size: 58px;
  margin-bottom: 20px;
}

h1.container{
  margin-bottom: 30px;
}

p {
  font-weight: 650;
  margin-top: 30px;
}

.navbar-brand img {
  height: 100%;
}




.logos {
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  height:110px;
  padding: 5px;
}

.logos img{
  max-width: 20%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: scale-down;;
}



#img-geschichte {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .soundmachine {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.jumbotron {
  padding: 0;
  padding-top:5%;
}

.content-shadow {
  box-shadow: 0px 3px 5px 0px #55555533;
}

.content {

  position: absolute;
  width: 70%;
  height: 95vh;
  padding: 3%;
  padding-right:0 ;
  padding-bottom: 2.5vh;
  background-color :transparent ;
  display: flex;
  flex-direction: row;


  padding: 0;
  /* border-left:"1px solid  #ccc" ; */

  top:50px;


  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.4); */
}

.content-secondary {

  position: absolute;
  width: 68.5%;
  height: 95vh;
  padding: 3%;
  padding-right:0 ;
  padding-bottom: 2.5vh;
  background-color :transparent ;
  display: flex;
  flex-direction: row;
  z-index: 100;


  padding: 0;
  /* border-left:"1px solid  #ccc" ; */

  top:50px;


  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.4); */
}


/* .content-container{
  overflow:scroll;
} */
.content-inactive {
  transition:0.6s;
  right: -72%;
}

.content-active{
  transition:0.6s;
  right:0;
}

.secondary-active{
  transition:0.6s;
  right:0;
}

.secondary-inactive {
  transition:0.6s;
  right: -73%;
}

.card-img-top {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}



.content-container {
  height: 100%;

  background-color: #FFFFFFFF;
  width:100%;
  max-width: 850px;
  /* max-width: 850px; */
  margin-left: 0px;
  margin-top: 0px;
  
  padding:1rem;
  padding-top: 61px;
}

@media screen and (min-width: 768px){
  .mobile-only{
    display: none;
  }
}


.mobile-menu-only {
  display: none;
}

@media screen and (max-width: 992px) {

  .mobile-menu-only{
    display: block
  }

  .videoBG {
    display: none;
  }

  .soundmachine {
    width: 80%;
    pointer-events: none;
    left:10%;
    top:30%;
    position: absolute;
  }

  body {
      background: url('./img/mobile-background.png');
      background-size: cover;
      background-position: center;
  }
  

  .content {
    width: 100%;
    left: 110% 
  }

  .separator-main{
    display: none !important;
  }

  .navbar-collapse{
    text-align: left;
  }

  .content-active {
    left:0;
  }

  .secondary-active {
    left:0;
  }

  .secondary-inactive {
    left:120%;
  }

  .content-secondary {
    width: 105%;
  }

  .right-backround {
    width: 0;
  }

  .content-container {
    width:100%
  }

  .subnavbar{
    visibility: hidden;
  }

  .subnavbar.active {
    visibility: hidden;
  }

  .subnavbar .navbar-nav { 
    flex-direction: row;
    
  }

  .subnavbar {
    left:30px !important;
  }

  .navbar-nav {
    flex-direction: column;
    background-color: white;
  }

  .navbar-collapse {
    
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    height: 100%;
    top: 50px;
    left: 0;
}
}


@media screen and (max-width: 767px){

  .desktop-only {
    display:none !important;
  }

  .mobile-only{
    display: block !important;
  }

  h1{

    font-size: 38px;
  }



} 

.subnavbar{
  height: 31px;
  width: 100vw;
  position:absolute;
  background-color: white;
  display: flex;
  z-index: 1;
  top:1px;
  transition: top .3s;
  box-shadow: 0px 0px 10px 0px #55555533;


}

.subnavbar .bg-light{
  box-shadow: none;
}

.subnavbar.active {
  display: block;
  transition: top .3s;
  top: 50px;
}

.subnavbar .nav-link {
  padding:0 0 0 10px;
}


.card{
  cursor: pointer;
  border: none;
}

.mitglieder .card{
  margin-bottom: 30px;
}

.mitglieder .card-title{

  font-weight: 700;
  font-size: 20px;
  margin-bottom:0;

}

.mitglieder .card-subtitle{
  text-transform: uppercase;
  font-weight: bold;
  font-size:.75rem
}

.mitglieder .card-body{
  padding: 0;
  padding-top: 5px;
}

.mitglieder .card-img-top{
  height: 15rem;
  width:15rem
}

.kollektive .card-img-top{
  height: 15rem;
  width:100%;
}

.kollektive .card-title{
  text-transform: uppercase;
  font-weight: bolder;

}

.kollektive .card-subtitle{
  text-transform: uppercase;
  font-weight: bold;

}

.events .card-img-top{
  height: 15rem;
  width:100%;
}


 /* Hide scrollbar for Chrome, Safari and Opera */
 .content-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 


.zitat{
  font-style: italic;
  text-align: center;
  padding: 2rem;
}


.videoBG {
  position:fixed;
  z-index: 0;
  object-fit: cover;
}
@media (min-aspect-ratio: 16/9) {
  .videoBG {
      width:100%;
      height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .videoBG { 
      width:auto;
      height: 100%;
  }
}




.bg-light {
  background-color: #fff !important;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #55555533;
}


/* p {
	-webkit-column-count: 2; 
	-moz-column-count:    2; 
	column-count:         2;
  	-webkit-column-gap:   20px; 
	-moz-column-gap:      20px; 
	column-gap:           20px;
	column-rule-color: #ccc; 
	column-rule-style:solid; 
	column-rule-width: 1px; 
	text-align: left; 
} */


.rotatedText{
  writing-mode: vertical-lr;
  transform: rotate(-180deg);
  color: #ccc;
  font-size: "14px";
  user-select: none;
  cursor: pointer;
  font-weight: 800;
}


.news-text {
  color: #ccc;
  font-size: "14px";
  user-select: none;
  cursor: pointer;
  font-weight: 800;
}


.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.5);
  padding: 8px 0 0 20px;
  font-size: 14px;
  font-weight: 800;
}

.nav-separator {
  padding:4px 0 0 6px;
}


.active.nav-link {
  text-decoration: underline !important;
  font-weight: bold;
  margin-top:3px ;
  letter-spacing: 0px;
  color: black ;
  opacity: 1;
  }

  .right-backround {
    background-color: #FFFFFFFF;
    background-image:url(./img/rect-stripes.svg);
    /* width: 250px; */
    height: 100%;
    flex-grow: 1;
}

.card {
  background-color: transparent;
}


.svg-icon {
  display: inline-flex;
  align-self: center;

}

.svg-icon  {
  height:0.8em;
  width: 0.8em;

}

.svg-icon.svg-baseline  {
  bottom: 0.10em;
  position: relative;
}




.vorstand-card {
  margin-top: 60px;
  min-height: 300px; 
}

.vorstand-card .card-subtitle{
  font-size: 1.1rem;
  font-weight: 650;
  margin-bottom: 10px;
}

.vorstand-card .card-body{
  padding-left:30px;
  padding-top:5px;
  font-size: .95rem;
}

.vorstand-card .card-title {
  font-weight: 800;
  font-size: 1.8rem;
}
a{
  color:#797979;
}

.footer li{
  display: inline;
  padding-left:10px
}

.footer {
  background-color: white;
  height: 2.5vh;
  display: block;
  box-shadow: 0px 0px 10px 0px #55555533;
  font-size: small;
  font-weight: 700;
}

form label , .form-inline label{
  padding-top: 0.95em;
  font-size: 1em;
  font-weight: 800;
  text-align: left;
  align-items: flex-start;
  justify-content: left;
}

textarea {
  border: 2px solid black !important;

}

form input ,.form-inline input {
  height: 2.4em;
}

input.form-control {
  border: 0 !important;
  outline: 0 !important;
  border-radius: 0;
  background: transparent !important;;
  border-bottom: 2px solid black !important;;

}

.form-control:focus{
  box-shadow: none;
}

.btn {
  background-color: white;
  color: black;
  border-radius: 0;

}

.btn:hover {
  background-color: black;
  color: white;
}

.back-link{
  font-weight:600;
  text-decoration:underline;
  color:black;

}

.back-link:hover{
  color:#797979;
}

.vibrate {
  animation: shm 2s infinite alternate;
}

@keyframes shm {
  from {
      margin-top:0px;
      animation-timing-function: cubic-bezier(0.25, 0.01, 0.55, 0.16);
  }
  25% {
      margin-top:5,395px;
      animation-timing-function: cubic-bezier(0.52, 0.44, 0.47, 0.44);
  }
  50% {
      margin-top:15px;
      animation-timing-function: cubic-bezier(0.53, 0.56, 0.48, 0.56);
  }
  75% {
      margin-top:25.605px;
      animation-timing-function: cubic-bezier(0.45, 0.84, 0.75, 0.99);
  }
  to {
      margin-top:30px;
  }
}


.visible-vid {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.05s linear;
}

.hidden-vid {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.05s, opacity 0.05s linear;
}

.hidden{
  visibility: hidden;
}

.visible {
  visibility: hidden;
}

.copyright{
  /* background-color: #00000088;
  color:white;
  font-weight: normal;
  font-size: small; */
  padding:0;
  margin: 0;
}


.content-news {

  position: absolute;
  width: 100%;
  height: 17vh;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;


  background-color: white;
  


}

.content-news .card-title {
  margin-top: 3px;
  margin-bottom: 0;
  font-size: 0.9rem;

}

.card-title.news-item-date {
  font-size: 1.1rem;
}

.content-news .card-img {
  width:100%;
  height:8vh;
  object-fit: cover;
  
}

.content-news .card-body{
  padding:0;
}

.news-active {

  transition:0.6s;
  bottom: 2.5vh;
}

.news-inactive {
  transition:0.6s;
  bottom: -15.5vh;
}

#news-triangle {
  transform: scale(-1,-1);
}

.schatten{
  

  position: absolute;
  bottom: 5vh;
  animation: shm2 2s infinite alternate;
}



@keyframes shm2 {
  from {
    left: 38vw;
    width:24vw;
      animation-timing-function: cubic-bezier(0.25, 0.01, 0.55, 0.16);
  }
  25% {

    left: 38.5vw;
    width:23vw;
      animation-timing-function: cubic-bezier(0.52, 0.44, 0.47, 0.44);
  }
  50% {
    left: 39vw;
    width:22vw;
      animation-timing-function: cubic-bezier(0.53, 0.56, 0.48, 0.56);
  }
  75% {
    left: 39.5vw;
    width:21vw;

      animation-timing-function: cubic-bezier(0.45, 0.84, 0.75, 0.99);
  }
  to {
   
    left: 40vw;
      width:20vw;
  }
}

.nav-right {
  color: rgba(0,0,0,.5);
  padding: 8px 0 0 20px;
  font-size: 14px;
  font-weight: 800;
}